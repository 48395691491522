import validate from "/workspace/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/workspace/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "already-auth": () => import("/workspace/middleware/already-auth.ts"),
  "auth-admin": () => import("/workspace/middleware/auth-admin.ts"),
  "auth-role-admin": () => import("/workspace/middleware/auth-role-admin.ts"),
  auth: () => import("/workspace/middleware/auth.ts")
}