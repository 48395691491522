
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexYfZ0W9psdaMeta } from "/workspace/pages/admin/agent/index.vue?macro=true";
import { default as indexNooKFoLI71Meta } from "/workspace/pages/admin/announce/add/index.vue?macro=true";
import { default as _91id_9355Te1Wrl57Meta } from "/workspace/pages/admin/announce/edit/[id].vue?macro=true";
import { default as indexe47M3acGKCMeta } from "/workspace/pages/admin/announce/index.vue?macro=true";
import { default as indexJb3tjV2tpBMeta } from "/workspace/pages/admin/bank/index.vue?macro=true";
import { default as index72BFR4vvrNMeta } from "/workspace/pages/admin/bet/index.vue?macro=true";
import { default as indexspJQnnz8gxMeta } from "/workspace/pages/admin/cash-admin/index.vue?macro=true";
import { default as indexDSOzzrPbBJMeta } from "/workspace/pages/admin/cash/index.vue?macro=true";
import { default as indexLOFyCMsOaXMeta } from "/workspace/pages/admin/commission/index.vue?macro=true";
import { default as indexRV8FfnUVLzMeta } from "/workspace/pages/admin/credit-agent/index.vue?macro=true";
import { default as indexjekZWtWboDMeta } from "/workspace/pages/admin/credit/index.vue?macro=true";
import { default as indexthnLPGW4B0Meta } from "/workspace/pages/admin/dashboard/index.vue?macro=true";
import { default as indexSno1nSRA3GMeta } from "/workspace/pages/admin/direct/index.vue?macro=true";
import { default as indexCMSpCkRcNPMeta } from "/workspace/pages/admin/feedback/index.vue?macro=true";
import { default as indexrItDGUw4fFMeta } from "/workspace/pages/admin/login/index.vue?macro=true";
import { default as _91id_93jCR3nDXYlUMeta } from "/workspace/pages/admin/lotto/approve/[id].vue?macro=true";
import { default as indexL9P4U8Rc1mMeta } from "/workspace/pages/admin/lotto/index.vue?macro=true";
import { default as _91slug_93riVSi7R5aiMeta } from "/workspace/pages/admin/lotto/yeekee/[slug].vue?macro=true";
import { default as _91id_93ORaBU3LBubMeta } from "/workspace/pages/admin/member/aff/[id].vue?macro=true";
import { default as _91id_93gagw5prPxaMeta } from "/workspace/pages/admin/member/bet/[id].vue?macro=true";
import { default as _91id_93pZjgKHHz0pMeta } from "/workspace/pages/admin/member/credit/[id].vue?macro=true";
import { default as indexLNyHbI8lHuMeta } from "/workspace/pages/admin/member/index.vue?macro=true";
import { default as _91id_93pnaOsjfkViMeta } from "/workspace/pages/admin/member/info/[id].vue?macro=true";
import { default as indexQpoLt2EuN2Meta } from "/workspace/pages/admin/profile/index.vue?macro=true";
import { default as indexbydkHcv1xkMeta } from "/workspace/pages/admin/report-bet/index.vue?macro=true";
import { default as indexs4rrFTgP5dMeta } from "/workspace/pages/admin/report-lotto/index.vue?macro=true";
import { default as indexs1iCVvtg9TMeta } from "/workspace/pages/admin/reward/index.vue?macro=true";
import { default as indexv4XVaBFRZsMeta } from "/workspace/pages/admin/sub-agent/index.vue?macro=true";
import { default as indexbUtNzZCa28Meta } from "/workspace/pages/affiliate/index.vue?macro=true";
import { default as membersYoKEfjbzZNMeta } from "/workspace/pages/affiliate/members.vue?macro=true";
import { default as revenueyl9fKPFncAMeta } from "/workspace/pages/affiliate/revenue.vue?macro=true";
import { default as withdrawTrxuLrEnN5Meta } from "/workspace/pages/affiliate/withdraw.vue?macro=true";
import { default as indexIXINnmHIuqMeta } from "/workspace/pages/betlist/index.vue?macro=true";
import { default as _91id_93abw3s6s15AMeta } from "/workspace/pages/betlist/number/[status]/[id].vue?macro=true";
import { default as indextLtUwfQZToMeta } from "/workspace/pages/betlist/summary/index.vue?macro=true";
import { default as indexA8QJITxcRFMeta } from "/workspace/pages/betlist/yeekee/index.vue?macro=true";
import { default as indexijgKENL9eNMeta } from "/workspace/pages/contact/index.vue?macro=true";
import { default as indexqsJBko05PIMeta } from "/workspace/pages/credits/index.vue?macro=true";
import { default as _91id_93VcBV0mqKiEMeta } from "/workspace/pages/games/game/[id].vue?macro=true";
import { default as indexlzjICwkvwRMeta } from "/workspace/pages/games/index.vue?macro=true";
import { default as _91id_931o5Zq36hlYMeta } from "/workspace/pages/games/recap/[id].vue?macro=true";
import { default as _91id_93gDaiySear1Meta } from "/workspace/pages/games/review/[id].vue?macro=true";
import { default as _91id_93ZgzBVG6SVtMeta } from "/workspace/pages/games/slug/[id].vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as indexzNsRf8CJBzMeta } from "/workspace/pages/menu/index.vue?macro=true";
import { default as createOfB9xGyXqZMeta } from "/workspace/pages/pattern/create.vue?macro=true";
import { default as indexpNtINuG2B8Meta } from "/workspace/pages/pattern/index.vue?macro=true";
import { default as indexn7j37APTbaMeta } from "/workspace/pages/profile/index.vue?macro=true";
import { default as indexLD8kwguP5cMeta } from "/workspace/pages/results/index.vue?macro=true";
import { default as indexKOikky435AMeta } from "/workspace/pages/rules/index.vue?macro=true";
import { default as depositJxhdPdvxdaMeta } from "/workspace/pages/transfer/deposit.vue?macro=true";
import { default as index5FQVEedV8EMeta } from "/workspace/pages/transfer/index.vue?macro=true";
import { default as withdrawLYA730Ye9vMeta } from "/workspace/pages/transfer/withdraw.vue?macro=true";
import { default as bankinsertc30W11UPKeMeta } from "/workspace/pages/user/bankinsert.vue?macro=true";
import { default as index7BwkdEjy2nMeta } from "/workspace/pages/user/forgetpassword/index.vue?macro=true";
import { default as profileGiTF2KAJONMeta } from "/workspace/pages/user/profile.vue?macro=true";
import { default as _91aff_93zedDiqG1CzMeta } from "/workspace/pages/user/register/affiliate/[aff].vue?macro=true";
import { default as indexNAp4vK6YtdMeta } from "/workspace/pages/user/register/index.vue?macro=true";
import { default as indexQDn5bCvU9XMeta } from "/workspace/pages/vip/index.vue?macro=true";
import { default as membersnPuMYK7q1MMeta } from "/workspace/pages/vip/members.vue?macro=true";
import { default as revenueWPMLHzxt09Meta } from "/workspace/pages/vip/revenue.vue?macro=true";
import { default as withdraw4lTQLMn1RTMeta } from "/workspace/pages/vip/withdraw.vue?macro=true";
export default [
  {
    name: "admin-agent",
    path: "/admin/agent",
    meta: indexYfZ0W9psdaMeta || {},
    component: () => import("/workspace/pages/admin/agent/index.vue")
  },
  {
    name: "admin-announce-add",
    path: "/admin/announce/add",
    meta: indexNooKFoLI71Meta || {},
    component: () => import("/workspace/pages/admin/announce/add/index.vue")
  },
  {
    name: "admin-announce-edit-id",
    path: "/admin/announce/edit/:id()",
    meta: _91id_9355Te1Wrl57Meta || {},
    component: () => import("/workspace/pages/admin/announce/edit/[id].vue")
  },
  {
    name: "admin-announce",
    path: "/admin/announce",
    meta: indexe47M3acGKCMeta || {},
    component: () => import("/workspace/pages/admin/announce/index.vue")
  },
  {
    name: "admin-bank",
    path: "/admin/bank",
    meta: indexJb3tjV2tpBMeta || {},
    component: () => import("/workspace/pages/admin/bank/index.vue")
  },
  {
    name: "admin-bet",
    path: "/admin/bet",
    meta: index72BFR4vvrNMeta || {},
    component: () => import("/workspace/pages/admin/bet/index.vue")
  },
  {
    name: "admin-cash-admin",
    path: "/admin/cash-admin",
    meta: indexspJQnnz8gxMeta || {},
    component: () => import("/workspace/pages/admin/cash-admin/index.vue")
  },
  {
    name: "admin-cash",
    path: "/admin/cash",
    meta: indexDSOzzrPbBJMeta || {},
    component: () => import("/workspace/pages/admin/cash/index.vue")
  },
  {
    name: "admin-commission",
    path: "/admin/commission",
    meta: indexLOFyCMsOaXMeta || {},
    component: () => import("/workspace/pages/admin/commission/index.vue")
  },
  {
    name: "admin-credit-agent",
    path: "/admin/credit-agent",
    meta: indexRV8FfnUVLzMeta || {},
    component: () => import("/workspace/pages/admin/credit-agent/index.vue")
  },
  {
    name: "admin-credit",
    path: "/admin/credit",
    meta: indexjekZWtWboDMeta || {},
    component: () => import("/workspace/pages/admin/credit/index.vue")
  },
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    meta: indexthnLPGW4B0Meta || {},
    component: () => import("/workspace/pages/admin/dashboard/index.vue")
  },
  {
    name: "admin-direct",
    path: "/admin/direct",
    meta: indexSno1nSRA3GMeta || {},
    component: () => import("/workspace/pages/admin/direct/index.vue")
  },
  {
    name: "admin-feedback",
    path: "/admin/feedback",
    meta: indexCMSpCkRcNPMeta || {},
    component: () => import("/workspace/pages/admin/feedback/index.vue")
  },
  {
    name: "admin-login",
    path: "/admin/login",
    meta: indexrItDGUw4fFMeta || {},
    component: () => import("/workspace/pages/admin/login/index.vue")
  },
  {
    name: "admin-lotto-approve-id",
    path: "/admin/lotto/approve/:id()",
    meta: _91id_93jCR3nDXYlUMeta || {},
    component: () => import("/workspace/pages/admin/lotto/approve/[id].vue")
  },
  {
    name: "admin-lotto",
    path: "/admin/lotto",
    meta: indexL9P4U8Rc1mMeta || {},
    component: () => import("/workspace/pages/admin/lotto/index.vue")
  },
  {
    name: "admin-lotto-yeekee-slug",
    path: "/admin/lotto/yeekee/:slug()",
    meta: _91slug_93riVSi7R5aiMeta || {},
    component: () => import("/workspace/pages/admin/lotto/yeekee/[slug].vue")
  },
  {
    name: "admin-member-aff-id",
    path: "/admin/member/aff/:id()",
    meta: _91id_93ORaBU3LBubMeta || {},
    component: () => import("/workspace/pages/admin/member/aff/[id].vue")
  },
  {
    name: "admin-member-bet-id",
    path: "/admin/member/bet/:id()",
    meta: _91id_93gagw5prPxaMeta || {},
    component: () => import("/workspace/pages/admin/member/bet/[id].vue")
  },
  {
    name: "admin-member-credit-id",
    path: "/admin/member/credit/:id()",
    meta: _91id_93pZjgKHHz0pMeta || {},
    component: () => import("/workspace/pages/admin/member/credit/[id].vue")
  },
  {
    name: "admin-member",
    path: "/admin/member",
    meta: indexLNyHbI8lHuMeta || {},
    component: () => import("/workspace/pages/admin/member/index.vue")
  },
  {
    name: "admin-member-info-id",
    path: "/admin/member/info/:id()",
    meta: _91id_93pnaOsjfkViMeta || {},
    component: () => import("/workspace/pages/admin/member/info/[id].vue")
  },
  {
    name: "admin-profile",
    path: "/admin/profile",
    meta: indexQpoLt2EuN2Meta || {},
    component: () => import("/workspace/pages/admin/profile/index.vue")
  },
  {
    name: "admin-report-bet",
    path: "/admin/report-bet",
    meta: indexbydkHcv1xkMeta || {},
    component: () => import("/workspace/pages/admin/report-bet/index.vue")
  },
  {
    name: "admin-report-lotto",
    path: "/admin/report-lotto",
    meta: indexs4rrFTgP5dMeta || {},
    component: () => import("/workspace/pages/admin/report-lotto/index.vue")
  },
  {
    name: "admin-reward",
    path: "/admin/reward",
    meta: indexs1iCVvtg9TMeta || {},
    component: () => import("/workspace/pages/admin/reward/index.vue")
  },
  {
    name: "admin-sub-agent",
    path: "/admin/sub-agent",
    meta: indexv4XVaBFRZsMeta || {},
    component: () => import("/workspace/pages/admin/sub-agent/index.vue")
  },
  {
    name: "affiliate",
    path: "/affiliate",
    meta: indexbUtNzZCa28Meta || {},
    component: () => import("/workspace/pages/affiliate/index.vue")
  },
  {
    name: "affiliate-members",
    path: "/affiliate/members",
    meta: membersYoKEfjbzZNMeta || {},
    component: () => import("/workspace/pages/affiliate/members.vue")
  },
  {
    name: "affiliate-revenue",
    path: "/affiliate/revenue",
    meta: revenueyl9fKPFncAMeta || {},
    component: () => import("/workspace/pages/affiliate/revenue.vue")
  },
  {
    name: "affiliate-withdraw",
    path: "/affiliate/withdraw",
    meta: withdrawTrxuLrEnN5Meta || {},
    component: () => import("/workspace/pages/affiliate/withdraw.vue")
  },
  {
    name: "betlist",
    path: "/betlist",
    meta: indexIXINnmHIuqMeta || {},
    component: () => import("/workspace/pages/betlist/index.vue")
  },
  {
    name: "betlist-number-status-id",
    path: "/betlist/number/:status()/:id()",
    meta: _91id_93abw3s6s15AMeta || {},
    component: () => import("/workspace/pages/betlist/number/[status]/[id].vue")
  },
  {
    name: "betlist-summary",
    path: "/betlist/summary",
    meta: indextLtUwfQZToMeta || {},
    component: () => import("/workspace/pages/betlist/summary/index.vue")
  },
  {
    name: "betlist-yeekee",
    path: "/betlist/yeekee",
    meta: indexA8QJITxcRFMeta || {},
    component: () => import("/workspace/pages/betlist/yeekee/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: indexijgKENL9eNMeta || {},
    component: () => import("/workspace/pages/contact/index.vue")
  },
  {
    name: "credits",
    path: "/credits",
    meta: indexqsJBko05PIMeta || {},
    component: () => import("/workspace/pages/credits/index.vue")
  },
  {
    name: "games-game-id",
    path: "/games/game/:id()",
    meta: _91id_93VcBV0mqKiEMeta || {},
    component: () => import("/workspace/pages/games/game/[id].vue")
  },
  {
    name: "games",
    path: "/games",
    meta: indexlzjICwkvwRMeta || {},
    component: () => import("/workspace/pages/games/index.vue")
  },
  {
    name: "games-recap-id",
    path: "/games/recap/:id()",
    meta: _91id_931o5Zq36hlYMeta || {},
    component: () => import("/workspace/pages/games/recap/[id].vue")
  },
  {
    name: "games-review-id",
    path: "/games/review/:id()",
    meta: _91id_93gDaiySear1Meta || {},
    component: () => import("/workspace/pages/games/review/[id].vue")
  },
  {
    name: "games-slug-id",
    path: "/games/slug/:id()",
    meta: _91id_93ZgzBVG6SVtMeta || {},
    component: () => import("/workspace/pages/games/slug/[id].vue")
  },
  {
    name: "index",
    path: "/",
    meta: index9S03gOyIVcMeta || {},
    component: () => import("/workspace/pages/index.vue")
  },
  {
    name: "menu",
    path: "/menu",
    meta: indexzNsRf8CJBzMeta || {},
    component: () => import("/workspace/pages/menu/index.vue")
  },
  {
    name: "pattern-create",
    path: "/pattern/create",
    meta: createOfB9xGyXqZMeta || {},
    component: () => import("/workspace/pages/pattern/create.vue")
  },
  {
    name: "pattern",
    path: "/pattern",
    meta: indexpNtINuG2B8Meta || {},
    component: () => import("/workspace/pages/pattern/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexn7j37APTbaMeta || {},
    component: () => import("/workspace/pages/profile/index.vue")
  },
  {
    name: "results",
    path: "/results",
    meta: indexLD8kwguP5cMeta || {},
    component: () => import("/workspace/pages/results/index.vue")
  },
  {
    name: "rules",
    path: "/rules",
    meta: indexKOikky435AMeta || {},
    component: () => import("/workspace/pages/rules/index.vue")
  },
  {
    name: "transfer-deposit",
    path: "/transfer/deposit",
    meta: depositJxhdPdvxdaMeta || {},
    component: () => import("/workspace/pages/transfer/deposit.vue")
  },
  {
    name: "transfer",
    path: "/transfer",
    meta: index5FQVEedV8EMeta || {},
    component: () => import("/workspace/pages/transfer/index.vue")
  },
  {
    name: "transfer-withdraw",
    path: "/transfer/withdraw",
    meta: withdrawLYA730Ye9vMeta || {},
    component: () => import("/workspace/pages/transfer/withdraw.vue")
  },
  {
    name: "user-bankinsert",
    path: "/user/bankinsert",
    meta: bankinsertc30W11UPKeMeta || {},
    component: () => import("/workspace/pages/user/bankinsert.vue")
  },
  {
    name: "user-forgetpassword",
    path: "/user/forgetpassword",
    meta: index7BwkdEjy2nMeta || {},
    component: () => import("/workspace/pages/user/forgetpassword/index.vue")
  },
  {
    name: "user-profile",
    path: "/user/profile",
    meta: profileGiTF2KAJONMeta || {},
    component: () => import("/workspace/pages/user/profile.vue")
  },
  {
    name: "user-register-affiliate-aff",
    path: "/user/register/affiliate/:aff()",
    meta: _91aff_93zedDiqG1CzMeta || {},
    component: () => import("/workspace/pages/user/register/affiliate/[aff].vue")
  },
  {
    name: "user-register",
    path: "/user/register",
    meta: indexNAp4vK6YtdMeta || {},
    component: () => import("/workspace/pages/user/register/index.vue")
  },
  {
    name: "vip",
    path: "/vip",
    meta: indexQDn5bCvU9XMeta || {},
    component: () => import("/workspace/pages/vip/index.vue")
  },
  {
    name: "vip-members",
    path: "/vip/members",
    meta: membersnPuMYK7q1MMeta || {},
    component: () => import("/workspace/pages/vip/members.vue")
  },
  {
    name: "vip-revenue",
    path: "/vip/revenue",
    meta: revenueWPMLHzxt09Meta || {},
    component: () => import("/workspace/pages/vip/revenue.vue")
  },
  {
    name: "vip-withdraw",
    path: "/vip/withdraw",
    meta: withdraw4lTQLMn1RTMeta || {},
    component: () => import("/workspace/pages/vip/withdraw.vue")
  }
]